.sidebarItem{
    width: 180px;
    display: flex;
    padding: 6px 12px;
    border-radius: 100px 100px 100px 100px;
    margin-left: 18px;
    margin-right: 18px;
    background-color: #F6F8FC;
    color: rgb(85, 78, 78);
    font-weight: 650;
    margin-bottom: 5px;
}

.sidebarItem:hover{
    background-color: rgba(0, 0, 0, 0.075);
    cursor: pointer;
}

.sidebarItem__main{
    display: flex;
}

.sidebarItem__main>p{
    margin-left: 14px;
}