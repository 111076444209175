.newFile {
    display: flex;
    align-items: center;
    padding: 12px 0;
    padding-left: 20px;
    margin-bottom: 10px;
    font-weight: 650;
}

.newFile__container {
    display: flex;
    align-items: center;
    /* TRouBLe */
    padding: 10px 32px 10px 8px;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2); 
    cursor: pointer;
    background-color: white;
}

.newFile__container>p{
    color: rgb(90, 83, 83);
    margin-left: 12px;
}

.newFile__container:hover{
    background-color: rgba(0, 0, 0, 0.075);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.progressBar__Div{
    z-index: 1000;
    position: fixed;
    background-color: #f9f9f9;
    min-width: 320px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    bottom: 0;
    right: 0;
    margin-right: 1em;
    margin-bottom: 1em;
    border-radius: 10px;
    border: 1px solid rgb(219, 219, 219);
    padding: 10px 10px 10px 10px;
}

.progressBar__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(109, 101, 101);
    font-weight: bold;
    padding-bottom: 10px;
}

.progressBar__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.fileInfo{
    margin-left: 15px;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.progressBar {
    position: relative;
    width: 200px;
    height: 10px;
    background-color: #f1f3f4;
    border-radius: 5px;
    margin-left: 20px;
}
  
.progressBar__fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #1a73e8;
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
}
  